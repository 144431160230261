import { commonApi } from "@/api/common.js";
import EventBus from "@/EventBus";
export default {
  components: {},
  data: () => ({
    userName: "",
    passWord: "",
    isLoading: false,

    // location: null,
    // gettingLocation: false,
    // errorStr: null,
    // center: { lat: 20.0360866, lng: 105.8158847 },
  }),
  mounted() {},
  // created() {
  //   //do we support geolocation
  //   if (!("geolocation" in navigator)) {
  //     console.log("Geolocation is not available.");
  //     return;
  //   }

  //   // get position
  //   navigator.geolocation.getCurrentPosition((pos) => {
  //     this.center.lat = pos.coords.latitude;
  //     this.center.longitude = pos.coords.longitude;
  //     // console.log("lat", pos.coords.latitude);
  //     // console.log("long", pos.coords.longitude);
  //     this.$refs.mapRef.$mapPromise.then((map) => {
  //       map.panTo({ lat: pos.coords.latitude, lng: pos.coords.longitude });
  //     });
  //   });
  // },
  methods: {
    async login() {
      // console.log(this.userName);
      if (!this.userName || !this.passWord) {
        this.$vs.notification({
          color: "danger",
          position: "top-right",
          duration: "2000",
          title: "Bạn chưa nhập tên đăng nhập / mật khẩu",
        });
        return;
      }

      this.isLoading = true;
      let body = {
        username: this.userName,
        password: this.passWord,
      };

      await commonApi.login(body).then((res) => {
        if (res.statusCode == 200) {
          localStorage.setItem(
            "userData",
            JSON.stringify({
              ...res.data,
            })
          );

          EventBus.$emit("afterLogin", true);

          if (res.data.roles.includes("customer")) {
            return this.$router.push({
              name: "memberDashboard",
            });
          } else if (res.data.roles.includes("driver")) {
            return this.$router.push({
              name: "driverDashboard",
            });
          } else if (res.data.roles.includes("administrator")) {
            return this.$router.push({
              name: "adminReport",
            });
          }
        }
      });
      this.isLoading = false;
    },
  },
};
